import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import { roleManagementAddress } from '../../abis/adders';
import RoleManagementABI from "../../abis/RoleManagement.json";
import Alert from '../Alert';

export default function ContactForm() {
  const location = useLocation();
  const [name, setName] = useState('');
  const [email, setEmail] = useState(location.state?.email || ''); // Initialize email with location.state if available
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(''); // New state for success message

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage(''); // Clear any previous messages

    try {
      // Check if MetaMask is installed
      if (!window.ethereum) {
        throw new Error("MetaMask is not installed");
      }

      // Request account access if needed
      await window.ethereum.request({ method: 'eth_requestAccounts' });

      // Create a provider and signer
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      // Create a contract instance
      const contract = new ethers.Contract(roleManagementAddress, RoleManagementABI, signer);

      // Submit contact data to blockchain
      const tx = await contract.submitContact(name, email, subject, message);
      await tx.wait();

      // Reset form fields after successful submission
      setName('');
      setEmail('');
      setSubject('');
      setMessage('');
      setSuccessMessage("Message submitted successfully!"); // Show success message

    } catch (error) {
      setErrorMessage(error.message);
      console.error("Error submitting message:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-lg-12">
            {/* Show success or error alert */}
            <Alert type="success" message={successMessage} onClose={() => setSuccessMessage('')} />
            <Alert type="danger" message={errorMessage} onClose={() => setErrorMessage('')} />
          </div>

          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Name</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="David John"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="col-lg-6">
            <label className="cs_input_label cs_heading_color">Email</label>
            <input
              type="email"
              className="cs_form_field"
              placeholder="example@gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Subject</label>
            <input
              type="text"
              className="cs_form_field"
              placeholder="Your subject"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              required
            />
          </div>
          <div className="col-lg-12">
            <label className="cs_input_label cs_heading_color">Message</label>
            <textarea
              cols={30}
              rows={10}
              className="cs_form_field"
              placeholder="Write something..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </div>
          
          <div className="col-lg-12">
            <button className="cs_btn m-2 cs_style_1" type="submit" disabled={isSubmitting}>
              <span>{isSubmitting ? 'Submitting...' : 'Submit'}</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
