import React from 'react';
import { FaUser, FaPhone, FaCalendarAlt, FaNotesMedical, FaClinicMedical, FaUserMd } from 'react-icons/fa';

export default function AppointmentFormStyle2() {
  return (
    <form className="cs_appointment_form p-4 rounded-4 shadow-lg bg-dark text-light">
      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          <FaUser className="me-2" /> Name (with last 4 digits of phone number)
        </label>
        <input
          type="text"
          className="form-control bg-dark text-light border-0 rounded-3 shadow-sm"
          placeholder="David John1234"
        />
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          <FaPhone className="me-2" /> Phone Number
        </label>
        <input
          type="text"
          className="form-control bg-dark text-light border-0 rounded-3 shadow-sm"
          placeholder="(123) 456 - 789"
        />
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          Age
        </label>
        <input
          type="text"
          className="form-control bg-dark text-light border-0 rounded-3 shadow-sm"
          placeholder="Enter age"
        />
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          Gender
        </label>
        <select className="form-select bg-dark text-light border-0 rounded-3 shadow-sm">
          <option>Select Gender</option>
          <option>Male</option>
          <option>Female</option>
          <option>Other</option>
        </select>
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          <FaCalendarAlt className="me-2" /> Preferred Date
        </label>
        <input
          type="text"
          className="form-control bg-dark text-light border-0 rounded-3 shadow-sm"
          placeholder="dd/mm/yyyy"
        />
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          <FaNotesMedical className="me-2" /> Health Problem
        </label>
        <textarea
          className="form-control bg-dark text-light border-0 rounded-3 shadow-sm"
          placeholder="Describe your health problem"
          rows="3"
        ></textarea>
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          <FaClinicMedical className="me-2" /> Department
        </label>
        <select className="form-select bg-dark text-light border-0 rounded-3 shadow-sm">
          <option>Select Department</option>
          <option>Cardiology</option>
          <option>Neurology</option>
          <option>Pediatrics</option>
          <option>Dermatology</option>
          <option>Orthopedics</option>
          <option>Radiology</option>
          <option>Surgery</option>
          <option>General Practice</option>
        </select>
      </div>

      <div className="form-group mb-4 position-relative">
        <label className="form-label text-secondary fw-semibold d-flex align-items-center">
          <FaUserMd className="me-2" /> Select Doctor
        </label>
        <select className="form-select bg-dark text-light border-0 rounded-3 shadow-sm">
          <option>Select a Doctor</option>
          <option>Dr. Smith (Cardiology)</option>
          <option>Dr. Johnson (Neurology)</option>
        </select>
      </div>

      <button type="submit" className="btn btn-gradient w-100 fw-semibold rounded-pill py-2 mt-3">
        Submit <i className="bi bi-arrow-right"></i>
      </button>
    </form>
  );
}
