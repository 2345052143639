// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-outline {
    border: 1px solid #ddd; /* Light gray outline */
    border-radius: 8px; /* Rounded corners */
    transition: border-color 0.3s ease; /* Smooth transition for hover */
  }
  
  .custom-outline:hover {
    border-color: #007bff; /* Change to blue on hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Admindash.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB,EAAE,uBAAuB;IAC/C,kBAAkB,EAAE,oBAAoB;IACxC,kCAAkC,EAAE,gCAAgC;EACtE;;EAEA;IACE,qBAAqB,EAAE,4BAA4B;EACrD","sourcesContent":[".custom-outline {\r\n    border: 1px solid #ddd; /* Light gray outline */\r\n    border-radius: 8px; /* Rounded corners */\r\n    transition: border-color 0.3s ease; /* Smooth transition for hover */\r\n  }\r\n  \r\n  .custom-outline:hover {\r\n    border-color: #007bff; /* Change to blue on hover */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
