import { Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Pages/Home';
import About from './components/Pages/About';
import Doctors from './components/Pages/Doctors';
import Blog from './components/Pages/Blog';
import Appointments from './components/Pages/Appointments';
import Layout2 from './components/Layout/Layout2';
import Layout5 from './components/Layout/Layout5';
import HomeStyle5 from './components/Pages/HomeStyle5';
import HomeStyle6 from './components/Pages/HomeStyle6';
import HomeStyle2 from './components/Pages/HomeStyle2';
import HomeStyle3 from './components/Pages/HomeStyle3';
import HomeStyle4 from './components/Pages/HomeStyle4';
import Layout3 from './components/Layout/Layout3';
import Layout4 from './components/Layout/Layout4';
import Departments from './components/Pages/Departments';
import DepartmentDetails from './components/Pages/DepartmentDetails';
import BlogDetails from './components/Pages/BlogDetails';
import DoctorDetails from './components/Pages/DoctorDetails';
import PatientProfile from './components/Pages/PatientProfile';
import Gallery from './components/Pages/Gallery';
import Timetable from './components/Pages/Timetable';
import Contact from './components/Pages/Contact';
import ErrorPage from './components/Pages/ErrorPage';
import HealthcareDAppABI from './abis/HealthcareDApp.json';
import RoleManagementABI from './abis/RoleManagement.json';
import { ethers } from 'ethers';  
import DoctorDashboard from "./components/DoctorDashboard";
import PharmacistDashboard from "./components/PharmacistDashboard";
import PatientRegistration from "./components/PatientRegistration";
import AdminDashboard from "./components/AdminDashboard";
import DoctorRegistration from "./components/DoctorRegistration";
import PharmacistRegistration from "./components/PharmacistRegistration";
import { roleManagementAddress, healthcareDAppAddress } from './abis/adders';
import ConnectWallet from './components/ConnectWallet';
import { useEffect, useState ,useContext } from 'react';
import './App.css';

function App() {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [role, setRole] = useState("");
  const [loading, setLoading] = useState(true);
  const [contractOwner, setContractOwner] = useState("");
  const [roleManagementContract, setRoleManagementContract] = useState(null);
  const [healthcareDAppContract, setHealthcareDAppContract] = useState(null);
 

  const targetNetwork = {
    chainId: '0x4268', // Correct chain ID for Holesky Testnet
    chainName: 'Holesky Testnet',
    nativeCurrency: {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: ['https://ethereum-holesky-rpc.publicnode.com'],
    blockExplorerUrls: ['https://holesky.etherscan.io'],
  };
  function switchNetwork() {
    if (window.ethereum) {
      window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: targetNetwork.chainId }],
      }).catch(async (error) => {
        if (error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [targetNetwork],
          });
        } else {
          console.error("Error switching network:", error);
        }
      });
    } else {
      alert('MetaMask is not installed. Please install it to use this feature.');
    }
  }

  // Effect to check for connected wallet on page load
  useEffect(() => {
    if (window.ethereum) {
      connectWalletOnLoad();
      
      // Listen for account changes and update state accordingly
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setCurrentAccount(accounts[0]);
        } else {
          setCurrentAccount(null); // Reset if no accounts connected
        }
      });
    }
  }, []);

  // Effect to load contract data once the wallet is connected
  useEffect(() => {
    if (currentAccount) {
      loadContractData();
    }
  }, [currentAccount]);

  // Automatically connect wallet if already connected
  async function connectWalletOnLoad() {
    const accounts = await window.ethereum.request({ method: "eth_accounts" });
    if (accounts.length > 0) {
      setCurrentAccount(accounts[0]);
    }
  }

  // Function to connect wallet on button click
  async function connectWallet() {
    if (window.ethereum) {
      try {
        const accounts = await window.ethereum.request({ method: "eth_requestAccounts" });
        setCurrentAccount(accounts[0]);
        console.log("Connected Account:", accounts[0]);
      } catch (error) {
        console.error("Error connecting wallet:", error);
      }
    } else {
      alert("Please install MetaMask to use this application.");
    }
  }

  // Function to load contract data and determine the role of the connected account
  async function loadContractData() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const roleManagementContract = new ethers.Contract(roleManagementAddress, RoleManagementABI, signer);
      const healthcareDAppContract = new ethers.Contract(healthcareDAppAddress, HealthcareDAppABI, signer);
      setRoleManagementContract(roleManagementContract);
      setHealthcareDAppContract(healthcareDAppContract);

      setLoading(true);

      // Fetch contract owner and compare with current account
      const owner = await roleManagementContract.contractOwner();
     
      setContractOwner(owner);

      if (currentAccount.toLowerCase() === owner.toLowerCase()) {
        setRole("admin");
        console.log("Role: Admin");
      } else {
        const isDoctor = await roleManagementContract.authorizedDoctors(currentAccount);
        if (isDoctor) {
          setRole("doctor");
          console.log("Role: Doctor");
        } else {
          const isPharmacist = await roleManagementContract.authorizedPharmacists(currentAccount);
          if (isPharmacist) {
            setRole("pharmacist");
            console.log("Role: Pharmacist");
          } else {
            const patient = await healthcareDAppContract.patients(currentAccount);
            if (patient && patient.name !== "") {
              setRole("patient");
              console.log("Role: Patient");
            } else {
              setRole("new");
              console.log("Role: New User");
            }
          }
        }
      }

      setLoading(false);
    } catch (error) {
      console.error("Error loading contract data:", error);
      setLoading(false);
    }
  }

  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  // Conditionally render content based on wallet connection status
  if (!currentAccount) {
    return <ConnectWallet connectWallet={connectWallet} />;
  }

  

  return (
    <>



  
      {/* Main Routes */}
      <Routes>
        <Route
          path="/"
          element={<Layout roleManagementContract={roleManagementContract} />}
        >
          <Route index element={<Home roleManagementContract={roleManagementContract} />} />
          <Route path="about" element={<About />} />
          <Route path="doctors" element={<Doctors roleManagementContract={roleManagementContract} />} />
          <Route path="/doctors/doctor-details/:addr" element={<DoctorDetails roleManagementContract={roleManagementContract} />} />
          <Route path="blog" element={<Blog />} />
          <Route path="blog/:blogId" element={<BlogDetails />} />
          <Route path="appointments" element={<Appointments roleManagementContract={roleManagementContract} />} />
          <Route path="departments" element={<Departments />} />
          <Route path="departments/:departmentId" element={<DepartmentDetails />} />
          <Route path="pricing-plan" element={<PatientProfile account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />} />
          <Route path="gallery" element={<Gallery />} />
          <Route path="timetable" element={<Timetable />} />
          <Route path="feedback" element={<Contact />} />
        </Route>
        
        <Route 
          path="/register-doctor" 
          element={<DoctorRegistration roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />} 
        />
        <Route 
          path="/register-pharmacist" 
          element={<PharmacistRegistration roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />} 
        />
        <Route
          path="/dashboard"
          element={
            role === "doctor" ? (
              <DoctorDashboard account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />
            ) : role === "pharmacist" ? (
              <PharmacistDashboard account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />
            ) : role === "new" ? (
              <Appointments roleManagementContract={roleManagementContract} />
            ) : role === "patient" ? (
              <PatientProfile account={currentAccount} roleManagementContract={roleManagementContract} healthcareDAppContract={healthcareDAppContract} />
            ) : role === "admin" ? (
              <AdminDashboard contractOwner={contractOwner} roleManagementContract={roleManagementContract} />
            ) : (
              <div className="alert-container d-flex justify-content-center align-items-center vh-100 bg-dark bg-opacity-75">
                <div className="alert-content d-flex align-items-start shadow-lg rounded-4 p-4 bg-gradient bg-light position-relative" style={{ maxWidth: '400px', border: '1px solid rgba(0, 0, 0, 0.1)' }}>
                  <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger" style={{ zIndex: '1', fontSize: '0.75rem', padding: '0.5rem 0.75rem', marginTop: '-0.75rem', marginRight: '-1rem' }}>
                    Error
                  </span>
                  <i className="bi bi-exclamation-triangle-fill text-warning display-4 me-3"></i>
                  <div className="alert-message">
                    <h4 className="fw-bold text-danger mb-1">Unknown Network</h4>
                    <p className="mb-3 text-secondary">Please connect to the <strong>Holesky Testnet</strong> to access the application.</p>
                    <button className="btn btn-outline-primary fw-semibold d-flex align-items-center gap-1" onClick={switchNetwork}>
                      <i className="bi bi-arrow-repeat"></i> Switch Network
                    </button>
                  </div>
                </div>
              </div>
            )
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  );
  
}

export default App;