import React from 'react';

function ConnectWallet({ connectWallet }) {
  return (
    <div className="d-flex justify-content-center align-items-center bg-light" style={{ height: '100vh' }}>
    <div className="card shadow-lg border-0 p-4" style={{ maxWidth: '400px', borderRadius: '20px' }}>
      <div className="card-body text-center">
        <h2 className="card-title text-primary mb-3 fw-bold" style={{ fontSize: '1.8rem', letterSpacing: '0.5px' }}>
          Welcome to <span className="text-info">Healthcare DApp</span>
        </h2>
        <p className="card-text text-secondary mb-4">
          Connect your wallet to access the decentralized healthcare platform and manage your medical interactions securely. Please connect to the <strong>Holesky Testnet</strong>.
        </p>
        <button onClick={connectWallet} className="btn btn-primary btn-lg w-100 d-flex align-items-center justify-content-center gap-2" style={{ borderRadius: '10px', transition: 'transform 0.2s' }}>
          <i className="bi bi-wallet2"></i> Connect Wallet
        </button>
        <div className="mt-3 text-secondary" style={{ fontSize: '0.85rem' }}>
          Don’t have a wallet? <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer" className="text-decoration-none fw-semibold text-info">Get MetaMask</a>
        </div>
      </div>
    </div>
  </div>
  
  );
}

export default ConnectWallet;
