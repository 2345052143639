import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle5 from "./Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { pageTitle } from "../helpers/PageTitle";

function DoctorDashboard({ account, healthcareDAppContract }) {
  pageTitle("Doctor Dashboard");
  useEffect(() => {
    // List of laboratory tests with charges (in ETH)
    setTests([
      { name: "Complete Blood Count (CBC)", charge: 0.01 },
      { name: "Blood Sugar (Glucose) Test", charge: 0.005 },
      { name: "Lipid Profile", charge: 0.015 },
      { name: "Liver Function Tests (LFT)", charge: 0.02 },
      { name: "Kidney Function Tests (KFT)", charge: 0.02 },
      { name: "Thyroid Function Tests", charge: 0.015 },
      { name: "Hemoglobin A1c (HbA1c)", charge: 0.008 },
      { name: "Vitamin D Test", charge: 0.03 },
      { name: "Vitamin B12 Test", charge: 0.025 },
      { name: "Blood Group and Rh Typing", charge: 0.01 },
      { name: "Coagulation Tests (PT, INR)", charge: 0.02 },
      { name: "Routine Urine Analysis", charge: 0.005 },
      { name: "Urine Culture", charge: 0.015 },
      { name: "Albumin Test (Protein in Urine)", charge: 0.01 },
      { name: "Pregnancy Test (hCG)", charge: 0.008 },
      { name: "Stool Routine Analysis", charge: 0.01 },
      { name: "Stool Culture", charge: 0.02 },
      { name: "Occult Blood Test", charge: 0.01 },
      { name: "Sputum Test", charge: 0.015 },
      { name: "Throat Swab Test", charge: 0.01 },
      { name: "Culture and Sensitivity Tests", charge: 0.025 },
      { name: "Malaria Tests (Peripheral Blood Smear, RDT, PCR)", charge: 0.02 },
      { name: "Dengue Tests (NS1, IgG/IgM, PCR)", charge: 0.03 },
      { name: "Typhoid Tests (Widal, Typhidot, Culture)", charge: 0.02 },
      { name: "Tuberculosis Tests (Mantoux, TB Gold, Sputum Culture)", charge: 0.03 },
      { name: "Leptospirosis Tests (IgM ELISA, MAT)", charge: 0.03 },
      { name: "HIV Tests (ELISA, Western Blot, RNA PCR)", charge: 0.05 },
      { name: "Hepatitis Tests (HBsAg, Anti-HCV, IgM/IgG)", charge: 0.04 },
      { name: "Influenza Tests (RIDT, PCR)", charge: 0.03 },
      { name: "Amoebiasis Tests (Stool Examination, Antigen Detection)", charge: 0.02 },
      { name: "Filariasis Tests (Blood Smear, Antigen Detection)", charge: 0.02 },
      { name: "Helminthic Infection Tests (Stool O&P Examination)", charge: 0.02 },
      { name: "Candidiasis Tests (Culture, KOH Preparation)", charge: 0.025 },
      { name: "Aspergillosis Tests (Galactomannan, Fungal Culture)", charge: 0.03 },
      { name: "Typhus Tests (Weil-Felix, PCR for Rickettsial DNA)", charge: 0.025 },
      { name: "Brucellosis Tests (SAT, Culture)", charge: 0.03 },
      { name: "Leishmaniasis Tests (Bone Marrow Smear, PCR)", charge: 0.04 },
      { name: "Insulin Levels Test", charge: 0.01 },
      { name: "Cortisol Test", charge: 0.015 },
      { name: "Electrolyte Panel (Sodium, Potassium, Calcium)", charge: 0.02 },
    ]);
  }, []);
  

  // State variables
  const [patients, setPatients] = useState([]);
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [patientProfile, setPatientProfile] = useState(null);
  const [medicine, setMedicine] = useState("");
  const [dosage, setDosage] = useState("");
  const [billDescription, setBillDescription] = useState("");
  const [billAmount, setBillAmount] = useState("");
  const [error, setError] = useState(null);
  const [prescriptions, setPrescriptions] = useState([]);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [medicines, setMedicines] = useState([{ medicine: "", dosage: "" }]);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false); // Loading state to manage async operations
  const [tests, setTests] = useState([]); // List of all available tests
  const [selectedTests, setSelectedTests] = useState([]); // Tests selected by the doctor
  const [testRemarks, setTestRemarks] = useState(""); // Remarks for the tests

  useEffect(() => {
    fetchPatientsForDoctor();
  }, []);

  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };

  // Fetch all patients for this doctor
  async function fetchPatientsForDoctor() {
    try {
      if (!healthcareDAppContract) {
        showAlert("Contract not loaded", "danger");
        return;
      }
      const patientNames = await healthcareDAppContract.viewAllPatientNamesByDoctor();
      setPatients(patientNames);
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Unable to fetch patients.";
      showAlert(errorReason, "danger");
    }
  }

  // Handle change in medicine and dosage fields
  const handleMedicineChange = (index, event) => {
    const newMedicines = [...medicines];
    newMedicines[index][event.target.name] = event.target.value;
    setMedicines(newMedicines);
  };

  // Add new medicine field
  const addMedicineField = () => {
    setMedicines([...medicines, { medicine: "", dosage: "" }]);
  };

  // Remove a medicine field
  const removeMedicineField = (index) => {
    const newMedicines = medicines.filter((_, i) => i !== index);
    setMedicines(newMedicines);
  };

  const issuePrescription = async () => {
    if (!selectedPatientName) {
      showAlert("Please select a patient", "danger");
      return;
    }
  
    setLoading(true); // Show loading indicator
    try {
      const medicinesArray = medicines.map((entry) => entry.medicine);
      const dosagesArray = medicines.map((entry) => entry.dosage);
  
      // Call smart contract function
      const tx = await healthcareDAppContract.issuePrescription(
        selectedPatientName,
        medicinesArray,
        dosagesArray
      );
      await tx.wait();
  
      showAlert("Prescription issued successfully!", "success");
  
      // Reset medicines to avoid re-render loop
      setMedicines([{ medicine: "", dosage: "" }]);
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Error issuing prescription.";
      showAlert(errorReason, "danger");
    }
    setLoading(false); // Hide loading indicator
  };
  

  // Function to view patient's profile, prescriptions, and records
  async function viewPatientProfile() {
    setLoading(true); // Show loading indicator
    try {
      const profile = await healthcareDAppContract.viewPatientProfile(selectedPatientName);

      // Format profile and associated data
      const formattedProfile = {
        patientAddress: profile[0],
        name: profile[1],
        number: profile[2],
        date: profile[3],
        age: profile[4].toString(),
        gender: profile[5],
        problem: profile[6]
      };

      const formattedPrescriptions = profile[7]?.map((prescription) => ({
        id: prescription[0].toString(),
        medicine: prescription[1],
        dosage: prescription[2],
        fulfilled: prescription[3] ? "Yes" : "No",
        doctorAddress: prescription[4],
        timestamp: new Date(prescription[5] * 1000).toLocaleString(),
        doctorName: prescription[6],
        healthProblem: prescription[7]
      })) || [];

      const formattedRecords = profile[8]?.map((record) => ({
        description: record[0],
        doctor: record[1],
        timestamp: new Date(record[2] * 1000).toLocaleString(),
        doctorAddress: record[3]
      })) || [];

      setPatientProfile(formattedProfile);
      setPrescriptions(formattedPrescriptions);
      setMedicalRecords(formattedRecords);
      setError(null);
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Error viewing patient profile.";
      showAlert(errorReason, "danger");
    }
    setLoading(false); // Hide loading indicator
  }


  const handleTestSelection = (test) => {
    if (selectedTests.includes(test)) {
      setSelectedTests(selectedTests.filter((t) => t !== test)); // Remove test if already selected
    } else {
      setSelectedTests([...selectedTests, test]); // Add test if not already selected
    }
  };
  
  const handleTestRemarks = (e) => {
    setTestRemarks(e.target.value);
  };
  
  const orderTests = async () => {
    if (!selectedPatientName) {
      showAlert("Please select a patient", "danger");
      return;
    }
  
    if (selectedTests.length === 0) {
      showAlert("Please select at least one test", "danger");
      return;
    }
  
    setLoading(true); // Show loading indicator
    try {
      // Validate selected tests and log for debugging
      console.log("Selected Tests:", selectedTests);
  
      // Ensure all tests have valid charge and name
      const validTests = selectedTests.filter((test) => test.name && test.charge);
  
      if (validTests.length === 0) {
        showAlert("Invalid test data selected. Please try again.", "danger");
        setLoading(false);
        return;
      }
  
      const testNames = validTests.map((test) => test.name); // Extract test names
      const testCosts = validTests.map((test) => ethers.utils.parseEther(test.charge.toString())); // Convert test costs to Ether format
  
      // Call smart contract function
      const tx = await healthcareDAppContract.orderTests(selectedPatientName, testNames, testCosts);
      await tx.wait();
  
      showAlert("Tests ordered successfully!", "success");
  
      // Reset fields after ordering
      setSelectedTests([]);
      setTestRemarks("");
    } catch (err) {
      console.error("Error in orderTests:", err);
      const errorReason = err?.data?.data?.reason || "Error ordering tests.";
      showAlert(errorReason, "danger");
    }
    setLoading(false); // Hide loading indicator
  };
  
  
  // Function to generate a bill
  async function generateBill() {
    if (!billAmount || isNaN(billAmount) || parseFloat(billAmount) <= 0) {
      showAlert("Please enter a valid bill amount in ETH.", "danger");
      return;
    }

    try {
      const billAmountInEther = ethers.utils.parseEther(billAmount.toString());
      const tx = await healthcareDAppContract.generateBill(
        selectedPatientName,
        billDescription,
        billAmountInEther
      );
      await tx.wait();
      showAlert("Bill generated successfully!", "success");
    } catch (err) {
      const errorReason = err?.data?.data?.reason || "Error generating bill.";
      showAlert(errorReason, "danger");
      console.log("Error details:", err); // Log for further debugging
    }
  }

 
return (
  <>
    <BannerSectionStyle5
      bgUrl="/images/about/banner_bg.svg"
      imgUrl="/images/about/doctotdashboard.png"
      title="Welcome to Doctor Dashboard"
      subTitle="Your Partner in Health and Wellness"
    />
    <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />

    <div className="container mt-5">
      {/* Alert Component */}
      <Alert
        type={alertType}
        message={alertMessage}
        onClose={() => setAlertMessage("")}
      />

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Custom Styles */}
      <style>{`
        .form-control, .form-select, textarea {
          border-radius: 20px;
          padding: 12px 15px;
          background-color: #f7f9fc;
          border: 1px solid #ced4da;
        }
        .form-control:focus, .form-select:focus, textarea:focus {
          border-color: #007bff;
          box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
        }
        .card {
          border-radius: 15px;
          overflow: hidden;
        }
        .btn-primary, .btn-success, .btn-danger {
          border-radius: 20px;
          padding: 10px 20px;
        }
        .table-responsive {
          overflow-x: auto;
          max-height: 400px;
        }
        .table th, .table td {
          white-space: nowrap;
        }
      `}</style>

      {/* Select a Patient */}
      <div className="card shadow-sm mb-4 border rounded custom-outline">
        <div className="card-body">
          <h5 className="card-title text-primary">Select a Patient</h5>
          <select
            value={selectedPatientName}
            onChange={(e) => setSelectedPatientName(e.target.value)}
            className="form-select shadow-sm mb-3"
          >
            <option value="">Select a Patient</option>
            {patients.map((patient, index) => (
              <option key={index} value={patient}>
                {patient}
              </option>
            ))}
          </select>
          <button
            onClick={viewPatientProfile}
            className="btn btn-primary w-100"
            disabled={!selectedPatientName}
          >
            {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
            View Patient Profile
          </button>
        </div>
      </div>

      {/* Display Patient Profile */}
      {patientProfile && (
        <div className="card shadow-sm mb-4 border rounded custom-outline">
          <div className="card-body">
            <h5 className="card-title">Patient Profile</h5>
            <p><strong>Address:</strong> {patientProfile.patientAddress}</p>
            <p><strong>Name:</strong> {patientProfile.name}</p>
            <p><strong>Phone Number:</strong> {patientProfile.number}</p>
            <p><strong>Registration Date:</strong> {patientProfile.date}</p>
            <p><strong>Age:</strong> {patientProfile.age}</p>
            <p><strong>Gender:</strong> {patientProfile.gender}</p>
            <p><strong>Present Health Problem:</strong> {patientProfile.problem}</p>

            {/* Display Prescriptions */}
            <h5 className="mt-4">Prescriptions</h5>
            {prescriptions.length > 0 ? (
              <div>
                <div className="table-responsive">
                  <table className="table table-striped table-hover table-bordered">
                    <thead className="table-dark">
                      <tr>
                        <th>Health Problem</th>
                        <th>Medicine</th>
                        <th>Dosage</th>
                        <th>Doctor</th>
                        <th>Fulfilled</th>
                        <th>Timestamp</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptions.map((prescription, index) => (
                        <tr key={index}>
                          <td>{prescription.healthProblem}</td>
                          <td>{prescription.medicine}</td>
                          <td>{prescription.dosage}</td>
                          <td>{prescription.doctorName}</td>
                          <td>{prescription.fulfilled}</td>
                          <td>{prescription.timestamp}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                {/* Card layout for smaller screens */}
                <div className="d-block d-md-none">
                  {prescriptions.map((prescription, index) => (
                    <div className="card mb-3" key={index}>
                      <div className="card-body">
                        <h5 className="card-title text-light">Health Problem: {prescription.healthProblem}</h5>
                        <p className="card-text text-light">Medicine: {prescription.medicine}</p>
                        <p className="card-text text-light">Dosage: {prescription.dosage}</p>
                        <p className="card-text text-light">Doctor: {prescription.doctorName}</p>
                        <p className="card-text text-light">Fulfilled: {prescription.fulfilled}</p>
                        <p className="card-text text-light">Timestamp: {prescription.timestamp}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <p>No prescriptions issued yet.</p>
            )}
          </div>
        </div>
      )}

      {/* Issue Prescription */}
      <div className="card shadow-sm mb-4 border rounded custom-outline">
        <div className="card-body">
          <h5 className="card-title text-primary">Issue Prescription</h5>
          {medicines.map((medicineEntry, index) => (
            <div key={index} className="row mb-3 align-items-end">
              <div className="col-md-8">
                <label className="form-label">Medicine</label>
                <textarea
                  name="medicine"
                  value={medicineEntry.medicine}
                  onChange={(e) => handleMedicineChange(index, e)}
                  placeholder="Enter all medicines with same dosage"
                  className="form-control shadow-sm"
                  rows="3"
                />
              </div>
              <div className="col-md-2">
                <label className="form-label">Dosage</label>
                <input
                  type="text"
                  name="dosage"
                  value={medicineEntry.dosage}
                  onChange={(e) => handleMedicineChange(index, e)}
                  placeholder="Enter dosage"
                  className="form-control shadow-sm m-2"
                />
              </div>
              <div className="col-md-2">
                {index === medicines.length - 1 ? (
                  <button onClick={addMedicineField} className="btn btn-primary w-100">
                    +
                  </button>
                ) : (
                  <button onClick={() => removeMedicineField(index)} className="btn btn-danger w-100">
                    -
                  </button>
                )}
              </div>
            </div>
          ))}
          <button onClick={issuePrescription} className="btn btn-success w-100">
            {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
            Issue Prescription
          </button>
        </div>
      </div>

      {/* Order Tests */}

      <div className="card shadow-sm mb-4 border rounded custom-outline">
  <div className="card-body">
    <h5 className="card-title text-primary">Order Laboratory Tests</h5>
    <div className="mb-3">
      <label className="form-label">Select Tests</label>
      <div className="form-check">
        {tests.map((test, index) => (
          <div key={index} className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id={`test-${index}`}
              checked={selectedTests.includes(test)}
              onChange={() => handleTestSelection(test)}
            />
            <label className="form-check-label" htmlFor={`test-${index}`}>
              {test}
            </label>
          </div>
        ))}
      </div>
    </div>
    <div className="mb-3">
      <label className="form-label">Remarks (Optional)</label>
      <textarea
        value={testRemarks}
        onChange={handleTestRemarks}
        placeholder="Enter any remarks or instructions for the tests"
        className="form-control shadow-sm"
        rows="3"
      />
    </div>
    <button onClick={orderTests} className="btn btn-success w-100">
      {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
      Order Tests
    </button>
  </div>
</div>

      {/* prderd tests by patient */}



      {patientProfile && patientProfile.orderedTests && (
  <div className="card shadow-sm mb-4 border rounded custom-outline">
    <div className="card-body">
      <h5 className="card-title text-primary">Ordered Tests</h5>
      {patientProfile.orderedTests.length > 0 ? (
        <ul className="list-group">
          {patientProfile.orderedTests.map((test, index) => (
            <li key={index} className="list-group-item">
              {test}
            </li>
          ))}
        </ul>
      ) : (
        <p>No tests ordered yet.</p>
      )}
    </div>
  </div>
)}
















    </div>

    <br />
    <Footer />
  </>
);
}

export default DoctorDashboard;
