import React, { useState } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle2 from "./Section/BannerSection/BannerSectionStyle2";
import Alert from "./Alert";
import { pageTitle } from "../helpers/PageTitle";

function DoctorRegistration({ roleManagementContract }) {
  pageTitle("Doctor Registration");
  const [name, setName] = useState("");
  const [qualification, setQualification] = useState("");
  const [specialization, setSpecialization] = useState("");
  const [department, setDepartment] = useState("");
  const [profilePicIPFSLink, setProfilePicIPFSLink] = useState("");
  const [contractInfo, setContractInfo] = useState("");
  const [key, setKey] = useState("");
  const [alertProps, setAlertProps] = useState({ type: "", message: "" });

  const departmentOptions = [
    { label: "Cardiology", value: 0 },
    { label: "Neurology", value: 1 },
    { label: "Dermatology", value: 2 },
    { label: "Pediatrics", value: 3 },
    { label: "Orthopedics", value: 4 },
    { label: "Oncology", value: 5 },
    { label: "Radiology", value: 6 },
    { label: "Surgery", value: 7 },
    { label: "General Practice", value: 8 },
  ];

  async function registerDoctor(e) {
    e.preventDefault();

    if (!roleManagementContract) {
      setAlertProps({ type: "danger", message: "Contract not loaded!" });
      return;
    }

    try { 
      const tx = await roleManagementContract.registerDoctor(
        name,
        qualification,
        specialization,
        department,
        profilePicIPFSLink,
        contractInfo,
        key,
        { value: ethers.utils.parseEther("0.001") }
      );
      await tx.wait();
      setAlertProps({ type: "success", message: "Doctor registered successfully!" });
    } catch (error) {
      const errorMessage = error?.data?.message || error?.message || "Error registering doctor.";
      const errorReason = errorMessage.includes("execution reverted")
          ? errorMessage.split("execution reverted: ")[1]
          : "Error registering doctor.";
      setAlertProps({ type: "danger", message: errorReason });
    }
  }

  return (
    <>
      <BannerSectionStyle2
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/banner_img.png"
        title="Welcome to Doctor Registration Page"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />
      <div className="container my-5 d-flex justify-content-center align-items-center">
        <div className="card shadow-lg rounded-lg p-4" style={{ maxWidth: "600px", width: "100%", backgroundColor: "#f8f9fc" }}>
          <div className="card-body">
            <h2 className="card-title text-center mb-4 text-primary">Doctor Registration</h2>

            <Alert
              type={alertProps.type}
              message={alertProps.message}
              onClose={() => setAlertProps({ type: "", message: "" })}
            />

            <form onSubmit={registerDoctor} className="form">
              <div className="form-group mb-4">
                <label className="form-label text-secondary font-weight-bold">Full Name</label>
                <input
                  type="text"
                  className="form-control rounded-pill shadow-sm border-primary"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Enter your full name"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary font-weight-bold">Qualification</label>
                <input
                  type="text"
                  className="form-control rounded-pill shadow-sm border-primary"
                  value={qualification}
                  onChange={(e) => setQualification(e.target.value)}
                  placeholder="e.g., MBBS, MD"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary  font-weight-bold">Specialization</label>
                <input
                  type="text"
                  className="form-control rounded-pill shadow-sm border-primary"
                  value={specialization}
                  onChange={(e) => setSpecialization(e.target.value)}
                  placeholder="e.g., Cardiologist"
                  required
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary  font-weight-bold">Department</label>
                <select
                  className="form-control rounded-pill shadow-sm border-primary"
                  value={department}
                  onChange={(e) => setDepartment(e.target.value)}
                  required
                >
                  <option value="">Select Department</option>
                  {departmentOptions.map((dept) => (
                    <option key={dept.value} value={dept.value}>
                      {dept.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary  font-weight-bold">Profile Data (Pinata Cloud Link)</label>
                <input
                  type="text"
                  className="form-control rounded-pill shadow-sm border-primary"
                  value={profilePicIPFSLink}
                  onChange={(e) => setProfilePicIPFSLink(e.target.value)}
                  placeholder="Enter Pinata Cloud link for profile data"
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary  font-weight-bold">Contract Information</label>
                <textarea
                  className="form-control rounded shadow-sm border-primary"
                  value={contractInfo}
                  onChange={(e) => setContractInfo(e.target.value)}
                  placeholder="Add any relevant contract information"
                  rows="2"
                />
              </div>
              <div className="form-group mb-4">
                <label className="form-label text-secondary  font-weight-bold">Security Key</label>
                <input
                  type="password"
                  className="form-control rounded-pill shadow-sm border-primary"
                  placeholder="Enter Security Key"
                  value={key}
                  onChange={(e) => setKey(e.target.value)}
                  required
                />
              </div>
              <div className="d-grid">
                <button type="submit" className="btn btn-primary rounded-pill shadow-sm font-weight-bold">
                  Register Doctor (0.001 ETH)
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
}

export default DoctorRegistration;
