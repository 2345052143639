import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle5 from "./Section/BannerSection/BannerSectionStyle5";
import Alert from "./Alert";
import { pageTitle } from "../helpers/PageTitle";

function PharmacistDashboard({ account, healthcareDAppContract, roleManagementContract }) {
  pageTitle("Pharmacist Dashboard");

  const [orderedTests, setOrderedTests] = useState([]); // List of ordered tests
  const [selectedTestIndexes, setSelectedTestIndexes] = useState([]); // Selected test indexes
  const [totalTestCost, setTotalTestCost] = useState(0); // Total cost of selected tests
  const [selectedTests, setSelectedTests] = useState([]); // Tests selected for fulfillment
  
  const [remarks, setRemarks] = useState(""); // State for remarks
  const [prescriptions, setPrescriptions] = useState([]);
  const [selectedPrescriptionId, setSelectedPrescriptionId] = useState("");
  const [selectedPatientName, setSelectedPatientName] = useState("");
  const [additionalMedicines, setAdditionalMedicines] = useState("");
  const [additionalCharge, setAdditionalCharge] = useState("");
  const [pharmacyName, setPharmacyName] = useState("");
  const [location, setLocation] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [loading, setLoading] = useState(false); // Define loading state here
  const [groupedPrescriptions, setGroupedPrescriptions] = useState({});
  const [selectedDoctor, setSelectedDoctor] = useState("");
  


  // Fetch pharmacy details automatically based on the logged-in account address
  useEffect(() => {
    if (account) {
      fetchPharmacyDetails();
    }
  }, [account]);

  useEffect(() => {
    groupPrescriptionsByDoctor();
  }, [prescriptions]);
  
  useEffect(() => {
    groupPrescriptionsByDoctor();
  }, [prescriptions]);
  
  // Function to show alert
  const showAlert = (message, type = "success") => {
    setAlertMessage(message);
    setAlertType(type);
  };
  async function fetchOrderedTests() {
    setLoading(true);
    try {
        const testData = await healthcareDAppContract.viewOrderedTests(selectedPatientName);
        const formattedTests = testData.map((test, index) => ({
            name: test.name,
            status: test.status,
            remarks: test.remarks,
            cost: ethers.utils.formatEther(test.cost.toString()), // Assuming backend returns cost in Wei
            timestamp: new Date(test.timestamp * 1000).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" }),
            index, // Index for backend
        }));
        setOrderedTests(formattedTests);
        showAlert("Ordered tests fetched successfully!", "success");
    } catch (err) {
        console.error("Error fetching ordered tests:", err);
        showAlert("Failed to fetch ordered tests.", "danger");
    }
    setLoading(false);
}

  
const handleTestSelection = (testIndex) => {
  setSelectedTests((prev) => {
      const isSelected = prev.includes(testIndex);
      return isSelected ? prev.filter((index) => index !== testIndex) : [...prev, testIndex];
  });
};

  
async function fulfillTests(e) {
  e.preventDefault();
  if (selectedTests.length === 0) {
      showAlert("No tests selected for fulfillment.", "danger");
      return;
  }

  setLoading(true);
  try {
      const totalCost = selectedTests.reduce(
          (sum, index) => sum + parseFloat(orderedTests[index].cost),
          0
      );
      const totalCostInWei = ethers.utils.parseEther(totalCost.toString());

      const tx = await healthcareDAppContract.updateTestStatus(
          selectedPatientName,
          selectedTests,
          "Fulfilled",
          "Tests completed successfully",
          totalCostInWei // Send total cost to backend
      );
      await tx.wait();
      showAlert("Tests fulfilled and bill updated successfully!", "success");
      setSelectedTests([]); // Clear selected tests
      fetchOrderedTests(); // Refresh tests
  } catch (err) {
      console.error("Error fulfilling tests:", err);
      showAlert("Failed to fulfill tests.", "danger");
  }
  setLoading(false);
}

  
  // Fetch pharmacy name and location from viewAllAuthorizedPharmacists
  async function fetchPharmacyDetails() {
    try {
      const pharmacistData = await roleManagementContract.viewAllAuthorizedPharmacists();
      const pharmacist = pharmacistData.find(([name, address, location]) => address.toLowerCase() === account.toLowerCase());

      if (pharmacist) {
        setPharmacyName(pharmacist[0]);
        setLocation(pharmacist[2]);
      } else {
        showAlert("Pharmacy details not found.", "danger");
      }
    } catch (error) {
      console.error("Error fetching pharmacy details:", error);
      showAlert("Failed to fetch pharmacy details.", "danger");
    }
  }

  // Fetch prescriptions for the selected patient name
  async function fetchPrescriptions(e) {
    e.preventDefault();
    setLoading(true); // Set loading to true when fetching prescriptions
    try {
      const prescriptionData = await healthcareDAppContract.viewPrescriptions(selectedPatientName);
      const prescriptionList = prescriptionData[1];
      const formattedPrescriptions = prescriptionList.map((prescription) => ({
        prescriptionId: ethers.BigNumber.from(prescription[0]).toString(),
        medicine: prescription[1],
        dosage: prescription[2].toString(),
        fulfilled: prescription[3] ? "Yes" : "No",
        patientAddress: prescription[4],
        timestamp: new Date(ethers.BigNumber.from(prescription[5]).toNumber() * 1000).toLocaleString(),
        doctorName: prescription[6],
      }));
      setPrescriptions(formattedPrescriptions);
      showAlert("Prescriptions fetched successfully", "success");
    } catch (err) {
      showAlert("You don't have permission to view prescriptions or the patient was not found.", "danger");
    }
    setLoading(false); // Set loading to false after fetching prescriptions
  }
  function groupPrescriptionsByDoctor() {
    const grouped = prescriptions.reduce((acc, prescription) => {
      // Only consider unfulfilled prescriptions
      if (!prescription.fulfilled || prescription.fulfilled === "No") {
        if (!acc[prescription.doctorName]) {
          acc[prescription.doctorName] = [];
        }
        acc[prescription.doctorName].push(prescription);
      }
      return acc;
    }, {});
  
    setGroupedPrescriptions(grouped);
  }
  
  async function fulfillPrescriptionsByDoctor(e) {
    e.preventDefault();
    setLoading(true);
  
    if (!selectedDoctor || !groupedPrescriptions[selectedDoctor] || groupedPrescriptions[selectedDoctor].length === 0) {
      showAlert("No prescriptions selected for fulfillment.", "danger");
      setLoading(false);
      return;
    }
  
    if (!additionalCharge || isNaN(additionalCharge) || parseFloat(additionalCharge) <= 0) {
      showAlert("Please enter a valid additional charge in ETH.", "danger");
      setLoading(false);
      return;
    }
  
    try {
      const additionalChargeInWei = ethers.utils.parseEther(additionalCharge);
      const prescriptionIds = groupedPrescriptions[selectedDoctor]
        .filter((p) => p.fulfilled === "No")
        .map((p) => p.prescriptionId);
  
      // Use the remarks value or fallback to default
      const finalRemarks = remarks.trim() || "Get well soon";
  
      await healthcareDAppContract.fulfillPrescription(
        selectedPatientName,
        prescriptionIds,
        finalRemarks, // Use remarks here
        additionalChargeInWei,
        pharmacyName,
        location
      );
  
      showAlert("Prescriptions fulfilled successfully!", "success");
    } catch (err) {
      console.error("Error fulfilling prescriptions:", err);
      showAlert("Failed to fulfill prescriptions.", "danger");
    }
    setLoading(false);
  }
  
  // Fulfill the selected prescription
  async function fulfillPrescription(e) {
    e.preventDefault();
    setLoading(true); // Set loading to true when fulfilling a prescription

    if (!additionalCharge || isNaN(additionalCharge) || parseFloat(additionalCharge) <= 0) {
      showAlert("Please enter a valid additional charge in ETH.", "danger");
      setLoading(false); // Set loading to false if validation fails
      return;
    }

    try {
      const additionalChargeInWei = ethers.utils.parseEther(additionalCharge);
      await healthcareDAppContract.fulfillPrescription(
        selectedPatientName,
        selectedPrescriptionId,
        additionalMedicines,
        additionalChargeInWei,
        pharmacyName,
        location
      );
      showAlert("Prescription fulfilled successfully", "success");
    } catch (err) {
      console.log(err);

      let errorReason = "You don't have permission to fulfill prescriptions or there was an issue with the transaction.";
      if (err?.data?.data?.reason) {
        errorReason = err.data.data.reason;
      } else if (err?.data?.message) {
        errorReason = err.data.message;
      }

      showAlert(errorReason, "danger");
    }
    setLoading(false); // Set loading to false after fulfilling the prescription
  }

  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/pharma.png"
        title="Welcome to Pharmacist Dashboard"
        subTitle="Your Partner in Health and Wellness"
      />
      <Header logoSrc="/images/logo.svg" variant="cs_heading_color" />
  
      <div className="container mt-5">
        {/* Alert Component */}
        <Alert
          type={alertType}
          message={alertMessage}
          onClose={() => setAlertMessage("")}
        />
  
        {/* Custom Styles */}
        <style>{`
          .form-control, .form-select, textarea {
            border-radius: 20px;
            padding: 12px 15px;
            background-color: #f7f9fc;
            border: 1px solid #ced4da;
          }
          .form-control:focus, .form-select:focus, textarea:focus {
            border-color: #007bff;
            box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
          }
          .card {
            border-radius: 15px;
            overflow: hidden;
          }
          .btn-primary, .btn-success {
            border-radius: 20px;
            padding: 10px 20px;
          }
          .custom-outline {
            border: 1px solid #d0d7de;
          }
          .table-responsive {
            overflow-x: auto;
            max-height: 400px;
          }
          .table th, .table td {
            white-space: nowrap;
          }
        `}</style>
  
        {/* Fetch Patient Prescriptions Form */}
        <div className="card shadow-sm mb-4 border rounded custom-outline">
          <div className="card-body">
            <h5 className="card-title text-primary">Fetch Patient Prescriptions</h5>
            <form onSubmit={fetchPrescriptions}>
              <div className="mb-3">
                <label className="form-label">Enter Patient Name</label>
                <input
                  type="text"
                  value={selectedPatientName}
                  onChange={(e) => setSelectedPatientName(e.target.value)}
                  placeholder="Enter patient name"
                  className="form-control shadow-sm"
                  required
                />
              </div>
              <button type="submit" className="btn btn-primary w-100" disabled={loading}>
                {loading ? <span className="spinner-border spinner-border-sm me-2"></span> : null}
                Fetch Prescriptions
              </button>
            </form>
          </div>
        </div>
  
        {/* Prescriptions Table */}
        {prescriptions.length > 0 && (
          <div className="card shadow-sm mb-4 border rounded custom-outline">
            <div className="card-body">
              <h5 className="card-title text-primary">Prescriptions for {selectedPatientName}</h5>
              <div className="table-responsive">
                <table className="table table-striped table-hover table-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th>Prescription ID</th>
                      <th>Medicine</th>
                      <th>Dosage</th>
                      <th>Fulfilled</th>
                      <th>Doctor</th>
                      <th>Timestamp</th>
                      <th>Doctor Address</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prescriptions.map((prescription, index) => (
                      <tr key={index}>
                        <td>{prescription.prescriptionId}</td>
                        <td>{prescription.medicine}</td>
                        <td>{prescription.dosage}</td>
                        <td>{prescription.fulfilled}</td>
                        <td>{prescription.doctorName}</td>
                        <td>{prescription.timestamp}</td>
                        <td>{prescription.patientAddress}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
  
        {/* Fulfill Prescription Form */}
        {/* Grouped Prescriptions by Doctor */}
        {Object.keys(groupedPrescriptions).length > 0 && (
  <div className="card shadow-sm mb-4 border rounded custom-outline">
    <div className="card-body">
      <h5 className="card-title text-primary">Grouped Prescriptions by Doctor</h5>
      <form onSubmit={fulfillPrescriptionsByDoctor}>
        <div className="mb-3">
          <label className="form-label">Select Doctor</label>
          <select
            value={selectedDoctor}
            onChange={(e) => setSelectedDoctor(e.target.value)}
            className="form-select shadow-sm"
            required
          >
            <option value="">Select Doctor</option>
            {Object.keys(groupedPrescriptions).map((doctor, index) => (
              <option key={index} value={doctor}>
                {doctor}
              </option>
            ))}
          </select>
        </div>

        <div className="mb-3">
          <label className="form-label">Additional Charge (in ETH)</label>
          <input
            type="text"
            value={additionalCharge}
            onChange={(e) => setAdditionalCharge(e.target.value)}
            placeholder="Enter additional charge"
            className="form-control shadow-sm"
            required
          />
        </div>

        {/* New Remarks Field */}
        <div className="mb-3">
          <label className="form-label">Remarks</label>
          <textarea
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            placeholder="Enter remarks or leave empty for default (Get well soon)"
            className="form-control shadow-sm"
            rows="3"
          />
        </div>

        <button type="submit" className="btn btn-success w-100" disabled={loading}>
          {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
          Fulfill Prescriptions
        </button>
      </form>
    </div>
  </div>
)}

       
{/* View and Fulfill Ordered Tests */}
<div className="card shadow-sm mb-4 border rounded custom-outline">
  <div className="card-body">
    <h5 className="card-title text-primary">Ordered Laboratory Tests</h5>

    {/* Button to Fetch Ordered Tests */}
    <button
      onClick={fetchOrderedTests}
      className="btn btn-primary w-100 mb-3"
      disabled={!selectedPatientName || loading}
    >
      {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
      Fetch Ordered Tests
    </button>

    {/* Display Ordered Tests */}
    {orderedTests.length > 0 ? (
      <div>
        <div className="table-responsive">
          <table className="table table-striped table-hover table-bordered">
            <thead className="table-dark">
              <tr>
                <th className="text-center">Select</th>
                <th>Test Name</th>
                <th>Cost (ETH)</th>
                <th>Status</th>
                <th>Remarks</th>
                <th>Timestamp (IST)</th>
              </tr>
            </thead>
            <tbody>
              {orderedTests.map((test, index) => (
                <tr key={index}>
                  <td className="text-center">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={`test-${index}`}
                      checked={selectedTestIndexes.includes(index)}
                      onChange={() => handleTestSelection(index, test.cost)}
                      disabled={test.status === "Fulfilled"} // Disable if already fulfilled
                    />
                  </td>
                  <td>{test.name}</td>
                  <td>{test.cost ? test.cost.toFixed(4) : "0.0000"} ETH</td>
                  <td>{test.status}</td>
                  <td>{test.remarks || "N/A"}</td>
                  <td>
                    {test.timestamp
                      ? new Date(test.timestamp * 1000).toLocaleString("en-IN", { timeZone: "Asia/Kolkata" })
                      : "N/A"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Display Total Test Cost */}
        <div className="mt-3">
          <h6 className="text-primary">Total Cost: {totalTestCost ? totalTestCost.toFixed(4) : "0.0000"} ETH</h6>
        </div>

        {/* Fulfill Tests Button */}
        <button
          onClick={fulfillTests}
          className="btn btn-success w-100 mt-3"
          disabled={loading || selectedTestIndexes.length === 0}
        >
          {loading && <span className="spinner-border spinner-border-sm me-2"></span>}
          Fulfill Tests and Generate Bill
        </button>
      </div>
    ) : (
      <p className="text-center text-muted">No tests ordered yet.</p>
    )}
  </div>
</div>




      </div>
      <br />
  
      <Footer />
    </>
  );
}

export default PharmacistDashboard;
