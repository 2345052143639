import React, { useState, useEffect } from "react";
import { ethers } from "ethers";
import Header from "./Header";
import Footer from "./Footer";
import BannerSectionStyle5 from "./Section/BannerSection/BannerSectionStyle5";
import BannerSectionStyle2 from "./Section/BannerSection/BannerSectionStyle2";
import { pageTitle } from "../helpers/PageTitle";
function PatientRegistration({ account  , healthcareDAppContract  , roleManagementContract}) {
  pageTitle("Patient Registration");
  const [name, setName] = useState("");
  const [age, setAge] = useState(0);
  const [gender, setGender] = useState("");
  const [problem, setProblem] = useState("");
  const [doctorAddress, setDoctorAddress] = useState("");
  const [authorizedDoctors, setAuthorizedDoctors] = useState([]);
  const [loading, setLoading] = useState(false);



  useEffect(() => {
    fetchAuthorizedDoctors();
  }, []);

  // Fetch all authorized doctors
  async function fetchAuthorizedDoctors() {

    setLoading(true);
    const allDoctors = await roleManagementContract.viewAllAuthorizedDoctors();
    setAuthorizedDoctors(allDoctors);
    setLoading(false);
  }

  // Register a new patient
  async function registerPatient(e) {
    e.preventDefault();
 
    
    try {
      const tx = await healthcareDAppContract.registerPatient(name, age, gender, doctorAddress, problem, {
        value: ethers.utils.parseEther("0.001"),  // Patient registration fee
      });
      await tx.wait();
      alert("Patient registered successfully!");
    } catch (error) {
      alert("Error registering patient: " + error.message);
    }
  }

  return (
    <div className="container min-vh-100 d-flex align-items-center justify-content-center">
      <div className="card shadow-lg" style={{ maxWidth: "600px", width: "100%" }}>
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Register as a Patient</h2>
          {loading ? (
            <div className="text-center">
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <p className="mt-2">Loading authorized doctors...</p>
            </div>
          ) : (
            <form onSubmit={registerPatient}>
              <div className="form-group mb-3">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label">Age</label>
                <input
                  type="number"
                  className="form-control"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                  required
                />
              </div>

              <div className="form-group mb-3">
                <label className="form-label">Gender</label>
                <select
                  className="form-select"
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div className="form-group mb-3">
                <label className="form-label">Health Problem</label>
                <input
                  type="text"
                  className="form-control"
                  value={problem}
                  onChange={(e) => setProblem(e.target.value)}
                  required
                />
              </div>

              <div className="form-group mb-4">
                <label className="form-label">Select Doctor</label>
                <select
                  className="form-select"
                  value={doctorAddress}
                  onChange={(e) => setDoctorAddress(e.target.value)}
                  required
                >
                  <option value="">Select a Doctor</option>
                  {authorizedDoctors.map((doctor, index) => (
                    <option key={index} value={doctor.doctorAddress}>
                      {doctor.name} ({doctor.specialization})
                    </option>
                  ))}
                </select>
              </div>

              <button
                type="submit"
                className="btn btn-primary w-100"
              >
                Register
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientRegistration;
